/* global process */

import { lazy } from 'react'
import { createRoot } from 'react-dom/client'
import {
  browserTracingIntegration,
  captureMessage,
  init,
  replayIntegration,
  setUser
} from '@sentry/react'
import { store } from 'ibiza'

import { HTP_ENV_FULL } from 'assets/lib/constants'
import fetch, { FetchError } from 'assets/lib/utils/fetch'
import isChunkLoadError from 'assets/lib/utils/is_chunk_load_error'
import ComponentManager from './components/component_manager.jsx'

const isDev = process.env.NODE_ENV === 'development'

if (process.env.SENTRY_DSN) {
  init({
    dsn: process.env.SENTRY_DSN,
    integrations: [
      browserTracingIntegration(),
      replayIntegration({
        workerUrl: '/sentry_replay_worker.js'
      })
    ],
    release: process.env.HEROKU_SLUG_COMMIT,
    environment: HTP_ENV_FULL,

    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1,

    tracesSampler: () => {
      return HTP_ENV_FULL === 'production' ? 0.2 : false
    },

    beforeSend: (event, hints) => {
      const { originalException } = hints

      if (originalException) {
        if (isChunkLoadError(originalException)) return null
        if (
          originalException instanceof FetchError &&
          originalException.isHandled &&
          event?.exception?.values?.length > 0 &&
          event.exception.values[0].mechanism !== undefined
        ) {
          event.exception.values[0].mechanism.handled = true
        }
      }

      return event
    },

    denyUrls: [/intercomcdn\.com/, /smartlook\.com/],

    ignoreErrors: [
      'TypeError: Illegal invocation',

      // See https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/13
      'Non-Error promise rejection captured'

      // 'TypeError: Failed to fetch',
      // 'TypeError: Cancelled',
      // 'TypeError: NetworkError when attempting to fetch resource.',
      // 'AbortError: The operation was aborted.'
    ]
  })
  const userData = {}
  const fromMetaData = (userKey, metaKey) => {
    const ele = document.getElementsByName(`htp:${metaKey}`)[0]
    if (ele) {
      userData[userKey] = ele.getAttribute('content')
    }
  }
  fromMetaData('booking_basket_id', 'booking_basket_id')
  fromMetaData('id', 'user_id')
  fromMetaData('email', 'user_email')
  fromMetaData('type', 'user_type')
  Object.keys(userData).length > 0 && setUser(userData)
}

store.debug = isDev
store.fetchFn = (res, opts) => {
  // Do not let fetch handle errors. Instead, let our error boundaries handle it.
  return fetch(res, { handleError: false, isCritical: true, ...opts }).json()
}

document.addEventListener('DOMContentLoaded', () => {
  // Lazy components.
  //
  // Lazy load non-react components using intersection observer. When coming into view, the
  // component HTML is fetched and rendered.
  document.querySelectorAll('.js__lazyComponent').forEach(ele => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const data = JSON.parse(ele.getAttribute('data'))

            // Fetches the component as HTML, and replace the intersecting element with the returned
            // component HTML.
            fetch(`/components/${data.componentPath}`, {
              handleError: false, // don't show error popup
              searchParams: { attributes: data.attributes },
              headers: {
                Accept: 'text/html',
                'Content-Type': 'text/html'
              }
            })
              .text()
              .then(text => {
                ele.outerHTML = text

                if (isDev) {
                  console.groupCollapsed('[LAZY] Rendered %o', data.componentPath)
                  console.log(JSON.parse(data.attributes))
                  console.groupEnd()
                }
              })
              .catch(() => {
                // fail silently, but still report to Sentry.
                captureMessage('Lazy component load failed', 'warning')
              })
          }
        })
      },
      { rootMargin: '100px' }
    )
    observer.observe(ele)
  })

  // React components
  // ================

  const rootEle = document.createElement('div')
  document.body.append(rootEle) // eslint-disable-line unicorn/prefer-node-append
  const root = createRoot(rootEle)

  // Find our components to load.
  const components = Array.from(document.querySelectorAll('.js__reactComponent'), domElement => {
    const data = JSON.parse(domElement.getAttribute('data'))

    return {
      component: lazy(() => import(`components/${data.componentPath}/index.entry.jsx`)),
      domElement,
      ...data
    }
  })

  // Render the component manager, which manages all components and the global store.
  root.render(<ComponentManager components={components} />)
})
